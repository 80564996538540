import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import SyncLoader from "react-spinners/SyncLoader"
// import SideNav from './SideNav';

function App() {
  const [response, setResponse] = useState("")
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState("")
  const [imageFile,setimageFile]=useState("")
  const [name, setName] = useState("")
  const [cnic, setCnic] = useState("")
  const [tab, setTab] = useState("add")

  // useEffect(() => {

  // }, [name, cnic])

  const handleOnNameChange = (e) => {
    setName(e.target.value)
  }

  const handleOnCnicChange = (e) => {
    setCnic(e.target.value)
  }

  const onImageSelection = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]))
    setimageFile(e.target.files[0])

  }

  const onclickSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    console.log(name)
    console.log(cnic)
    const formData = new FormData();
    formData.append("name",name)
    formData.append("status",cnic)
    formData.append("image",imageFile)
    
    fetch('https://imgai.comocrm.com/add', {
      method: 'POST',
      body:formData
    })
      .then(response => response.json())
      .then(data => {
        setResponse(data)
        setLoading(false)
      })
      .catch((error) => {

        setResponse(error)
        setLoading(false)
      });

  }

  const onSearchSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData();
    formData.append("image",imageFile)
    fetch('https://imgai.comocrm.com/search', {
      method: 'POST',
      body:formData
    })
      .then(response => response.json())
      .then(data => {
        setResponse(data)
        setLoading(false)
      })
      .catch((error) => {

        setResponse(error)
        setLoading(false)
      });

  }



  return (
    <div className="App">
      <div>
        <div style={{ margin: "20px 0 0 0" }}>
          <button
            onClick={() => setTab("add")}
            style={{
              backgroundColor: "#6ad0d4",
              color: "white"
            }}
          > Add
          </button>
          <button
            onClick={() => setTab("search")}
            style={{
              margin: "10px",
              backgroundColor: "#6ad0d4",
              color: "white"
            }}
          > Search
          </button>
        </div>
      </div>
      <div style={{ alignItems: "center" }}>

        {tab == "add" && (
          <div>
            <form>
              <h1>Add A Person</h1>
              <div>
                <input
                  id="name"
                  type="text"
                  placeholder="Name"
                  onChange={handleOnNameChange}
                />
              </div>
              <br></br>
              <div>
                <input
                  id="cnic"
                  type="text"
                  placeholder="CNIC"
                  onChange={handleOnCnicChange}
                />
              </div>
              <br></br>
              <div>
                <br></br>

                <input type="file"
                  onChange={onImageSelection}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <img src={image} width="300" height="300" />

              </div>
              <br></br>
              <div>
                <button
                  onClick={onclickSubmit}
                  style={{
                    backgroundColor: "#6ad0d4",
                    color: "white"
                  }}
                > Submit
                </button>
              </div>
              <div style={{ marginTop: "10px" }}>
                {loading ?
                  <SyncLoader size={5} loading={loading} color={"#0066ff"} /> :
                  <>
                    <h4>{response === "" ? "" : "Reponse :"}</h4>
                    <h4 style={{ color: 'red' }}>{response === "" ? "" : response.msg}</h4>
                  </>
                }
              </div>
            </form>
          </div>
        )}

        {tab == "search" && (
          
          <div>
            <div>
                <br></br>

                <input type="file"
                  onChange={onImageSelection}
                />
              </div>
            <div style={{ marginTop: "10px" }}>
            <img src={image} width="300" height="300" />

          </div>
            <br></br>
            <div>
              <button
                onClick={onSearchSubmit}
                style={{
                  backgroundColor: "#6ad0d4",
                  color: "white"
                }}
              > Submit
              </button>
            </div>
            <div style={{ marginTop: "10px" }}>
              {loading ?
                <SyncLoader size={5} loading={loading} color={"#0066ff"} /> :
                <>
                  <h4>{response === "" ? "" : "Reponse :"}</h4>
                  <h4 style={{ color: 'red' }}>{response === "" ? "" : response.name}</h4>
                </>
              }
            </div></div>
        )}


      </div>
    </div>
  );
}

export default App;
